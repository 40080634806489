import { Calendar } from 'common/components/Icons';
import ArrowDownIcon from 'common/components/Icons/ArrowDownIcon';
import { i18n } from 'common/locale';

// const MuiDateTimePicker: {
//   defaultProps: { slotProps: DatePickerSlotProps<Date, true>; slots: any; format: any; views: any };
// }
const MuiDateTimePicker = {
  defaultProps: {
    slots: { openPickerIcon: Calendar },
    slotProps: {
      textField: {
        sx: {
          maxWidth: 141,
          '& .MuiInputBase-sizeSmall': {
            maxWidth: 114,
          },
        },
      },
      actionBar: {
        actions: ['today'],
      },
      switchViewButton: ArrowDownIcon,
    },
    format: i18n.dateTimeFormatCompact,
    views: ['month', 'day', 'hours', 'minutes'],
  },
};

export default MuiDateTimePicker;
